import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp'
import { useFormContext } from 'react-hook-form'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'

const predicateHelper = (predicate) => (
  predicate
    .replace("http://www.w3.org/ns/dcat#", "dcat:")
    .replace("http://purl.org/dc/terms/", "dct:")
    .replace("http://www.w3.org/ns/adms#", "adms:")
    .replace("http://xmlns.com/foaf/0.1/", "foaf:")
    .replace("http://notre_prefix_hdh#", "hdh:")
    .replace("http://www.w3.org/2004/02/skos/core#", "skos:")
    .replace("http://www.w3.org/2006/vcard/ns#", "vcard:")
    .replace("http://www.w3.org/ns/locn#", "locn:")
);

export default function GenericCard({ category, metadata, showEmpty }) {
  const { register } = useFormContext();
  const allEmpty = showEmpty
    ? false
    : metadata.object
        .flat()
        .every((element) => element === null || element === '')
  if (['', 'Header'].includes(category)) {
    return null
  }

  if (!allEmpty) {
    return (
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: '12px', marginBottom: '20px' }}
      >
        {category === 'Général' ? null : (
          <Toolbar>
            <Typography variant="h5">{category}</Typography>
          </Toolbar>
        )}
        <Table size="small">
          <TableBody>
            {metadata.object.map((data, index) => {
              if (
                (metadata.shown[index] &&
                  !data.every(
                    (element) => element === null || element === ''
                  )) ||
                showEmpty
              ) {
                console.log();
                return (
                  <TableRow
                    key={metadata.fr_field_name[index]}
                    data-test={`TableRow-${metadata.fr_field_name[index]}`}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '15rem',
                        maxWidth: '15rem',
                        overflowWrap: 'break-word',
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography
                          sx={{ whiteSpace: 'pre-line' }}
                          data-test={`TableCell-Typography-${metadata.fr_field_name[index]}`}
                        >
                          {metadata.fr_field_name[index]}
                        </Typography>
                        {metadata.helper[index] ? (
                          <Tooltip
                            title={metadata.helper[index]}
                            sx={{ color: '#612491' }}
                            data-test={`TableCell-Tooltip-${metadata.fr_field_name[index]}`}
                          >
                            <HelpOutlineSharpIcon />
                          </Tooltip>
                        ) : null}
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '15rem',
                        maxWidth: '15rem',
                        overflowWrap: 'break-word',
                      }}
                    >
                      <TextField
                        sx={{ whiteSpace: 'pre-line', width: '100%' }}
                        data-test={`CellContent-Typography-${data}`}
                        defaultValue={data}
                        {...register(predicateHelper(metadata.predicate[index]))}
                      />
                    </TableCell>
                  </TableRow>
                )
              }
              return null
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

GenericCard.propTypes = {
  category: PropTypes.string.isRequired,
  metadata: PropTypes.shape.isRequired,
  showEmpty: PropTypes.bool.isRequired,
}
