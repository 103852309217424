import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TextFieldSimple } from '../../styles/TextFields';

const Links = () => {
  const { register } = useFormContext();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
      }}
    >
      <TextFieldSimple
        label='Adresse email de contact'
        sx={{ width: '50%' }}
        {...register('contactEmail')}
      />
      <TextFieldSimple
        label='URL du lien "À propos de nous"'
        sx={{ width: '50%' }}
        {...register('aboutUsUrl')}
      />
    </Box>
  );
};

export { Links };
