import AccountCircle from '@mui/icons-material/AccountCircle'
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp'
import SettingsIcon from '@mui/icons-material/Settings'
import StorageIcon from '@mui/icons-material/Storage'
import GroupIcon from '@mui/icons-material/Group'
import { Link, Stack, Tooltip } from '@mui/material'
import { useLocation } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import siteLogo from '../assets/site_logo.png'
import { ButtonSimple, ButtonText } from '../styles/Buttons'
import { useSettings } from '../utils/settings'
import UserService from '../utils/keycloak'

export default function ButtonAppBar() {
  const location = useLocation();
  const settings = useSettings();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ boxShadow: 'none' }}>
        <Toolbar>
          <Box
            component={Link}
            href="/"
            data-test="LinkLogo"
            sx={{
              padding: '12px',
              paddingLeft: '0',
              maxWidth: '85.55px',
              maxHeight: '58.61px',
            }}
          >
            <Box
              component="img"
              data-test="logo"
              alt="Health Data Hub Logo"
              src={settings?.logo ?? siteLogo}
              sx={{
                paddingLeft: '0',
                maxWidth: '85.55px',
                maxHeight: '58.61px',
              }}
            />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
              paddingRight: '12px',
            }}
          >
            Back-office
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={1}>
            <Stack direction="row">
              <Tooltip title="Gestion des bases">
                <ButtonText
                  color="inherit"
                  href="/"
                  startIcon={<StorageIcon sx={{ paddingLeft: '8px' }} />}
                  data-test="DatabaseButton"
                  sx={{
                    backgroundColor: location.pathname === '/' ? 'white' : 'transparent',
                    color: location.pathname === '/' ? 'primary.main' : 'white',
                    '&:hover': {
                      backgroundColor: location.pathname === '/' ? 'white' : 'primary.main',
                    }
                  }}
                >
                  Gestion des bases
                </ButtonText>
              </Tooltip>
              <Tooltip title="Gestion des utilisateurs">
                <ButtonText
                  color="inherit"
                  href="/users"
                  startIcon={<GroupIcon sx={{ paddingLeft: '8px' }} />}
                  data-test="UsersButton"
                  sx={{
                    backgroundColor: location.pathname === '/users' ? 'white' : 'transparent',
                    color: location.pathname === '/users' ? 'primary.main' : 'white',
                    '&:hover': {
                      backgroundColor: location.pathname === '/users' ? 'white' : 'primary.main',
                    }
                  }}
                >
                  Utilisateurs
                </ButtonText>
              </Tooltip>
              <Tooltip title="Paramètres">
                <ButtonText
                  color="inherit"
                  href="/settings"
                  startIcon={<SettingsIcon sx={{ paddingLeft: '8px' }} />}
                  data-test="SettingsButton"
                  sx={{
                    backgroundColor: location.pathname === '/settings' ? 'white' : 'transparent',
                    color: location.pathname === '/settings' ? 'primary.main' : 'white',
                    '&:hover': {
                      backgroundColor: location.pathname === '/settings' ? 'white' : 'primary.main',
                    }
                  }}
                >
                  Paramètres
                </ButtonText>
              </Tooltip>
              {/* <Tooltip title="Profil utilisateur">
                <ButtonText
                  href={`http://localhost:8080/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/account`}
                  data-test="userAccount"
                  color="inherit"
                  startIcon={<AccountCircle sx={{ paddingLeft: '8px' }} />}
                >
                  Mon compte
                </ButtonText>
              </Tooltip> */}
            </Stack>
            <Stack direction="row" justifyContent="right">
              <Tooltip title="Déconnexion">
                <ButtonSimple
                  data-test="disconnectButton"
                  color="secondary"
                  startIcon={<LogoutSharpIcon />}
                  variant="contained"
                  onClick={() => UserService.doLogout()}
                >
                  Déconnexion
                </ButtonSimple>
              </Tooltip>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar variant="dense" />
    </Box>
  )
}

