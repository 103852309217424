import { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ADMIN_API_URL;

const SettingsContext = createContext(null);

export const SettingsProvider = ({ children, token }) => {
  const [data, setData] = useState(null);

  const fetch = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_URL}/config/settings`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const logo = await axios.get(`${BASE_URL}/config/logo`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });
      setData({ ...response.data, logo: logo.data.image });
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setData({ error: 'Failed to load data' });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetch();
    }
  }, [fetch, token]);

  const providedData = useMemo(() => ({ fetch, ...data }), [fetch, data]);

  return (
    <SettingsContext.Provider value={providedData}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
