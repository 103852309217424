import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@mui/material';

const AssignDb = ({ isOpen, setModalOpen }) => {
  const createUser = () => {
  };

  return (
    <Dialog
      maxWidth='xs'
      open={isOpen}
    >
      <DialogTitle>Valider la suppression</DialogTitle>
      <DialogContent>
        <DialogContentText>Êtes-vous sûr de vouloir supprimer ce jeu de données ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setModalOpen(false)}>
          Annuler
        </Button>
        <Button onClick={() => createUser()}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AssignDb };
