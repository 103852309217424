import { Badge, Box } from '@mui/material'

/**
 * Sets the loading state to false after a delay.
 * @param {function} setLoading - The function to set the loading state.
 * @returns {void}
 */
export function CardsLoaded(setLoading) {
  setTimeout(() => {
    setLoading(false)
  }, 250)
}

/**
 * Flattens a nested object into a one-dimensional array of all keys and values.
 * @param {Object} obj - The object to be flattened.
 * @returns {Array} - A one-dimensional array of all keys and values in the object.
 */
export function flatten(obj) {
  let result = []
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result.push(key)
      if (typeof obj[key] === 'object') {
        result = result.concat(flatten(obj[key]))
      } else {
        result.push(obj[key])
      }
    }
  }
  return result
}

/**
 * gets User Token
 * @returns {Object} userToken
 */
export function getToken() {
  const tokenString = sessionStorage.getItem('token')
  const userToken = JSON.parse(tokenString)
  return userToken
}

/**
 * generates header field for API calls
 * @param {Object} JWT userToken
 * @returns {string} `Bearer ${JWT.access_token}`
 */
export function generateHeader(JWT) {
  const headers = {}
  if (JWT) {
    headers.Authorization = `Bearer ${JWT.access_token}`
  }
  return headers
}

/**
 * gets users email token
 * @returns {Object} userToken
 */
export function getTokenMail() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return { access_token: urlParams.get('token'), token_type: 'bearer' }
}

/**
 * constructs url using .env
 * @returns {string}
 */
export function URL_API() {
  const env = process.env.REACT_APP_ENV
  const url = process.env.REACT_APP_URL
  const mainPort = process.env.REACT_APP_UVICORN_PORT
  const adminPort = process.env.REACT_APP_ADMIN_UVICORN_PORT
  if (env === 'prod') {
    return [`${url}/api`]
  }
  return [`${url}:${mainPort}`, `${url}:${adminPort}`]
}

/**
 * Calculates Search number of hits for badge
 * @param {Array} listStep
 * @param {bool} tree if true will change if condition
 * @returns {Number}
 */

export function ifBadge(listStep, tree) {
  let number = 0
  if (listStep !== null) {
    const altstep = tree ? Object.values(listStep).length : listStep.length
    for (let step = 0; step < altstep; step += 1) {
      if (
        document.location.href.includes('input=') &&
        JSON.stringify(
          tree
            ? Object.values(listStep)[step]
            : Object.values(listStep[step].Value)
        )
          .toLowerCase()
          .includes(
            document.location.href
              .split('input=')[1]
              .split('&')[0]
              .toLowerCase()
          )
      ) {
        number += 1
      }
    }
  }

  return number
}

/**
 *
 * @param {*} listStep
 * @param {*} listStepName
 * @returns
 */
export function handleLabel(listStep, listStepName) {
  const number = ifBadge(listStep, false)
  if (number) {
    return (
      <Badge
        data-test="hitsBadge"
        invisible={!number}
        color="secondary"
        badgeContent={number}
      >
        <Box m={1}>{listStepName}</Box>
      </Badge>
    )
  }
  return listStepName
}
