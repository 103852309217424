import { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  TextField
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { SnackContext } from '../../utils/snack';
import { createUser } from '../../lib/keycloak';
import UserService from '../../utils/keycloak';

const CreateUser = ({ isOpen, setModalOpen, refresh }) => {
  const { formState: { errors }, handleSubmit, register } = useForm();
  const { handleSnack } = useContext(SnackContext);

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={isOpen}
    >
      <form
        onSubmit={handleSubmit(async(values) => {
          try {
            await createUser(UserService.getToken(), {
              firstName: values?.firstName,
              lastName: values?.lastName,
              email: values?.email,
              password: values?.password
            });
            setModalOpen(false);
            handleSnack('Utilisateur créé', 'success');
            refresh();
          } catch (error) {
            console.error(error);
            handleSnack('Erreur lors de la création de l\'utilisateur', 'error');
          }
        })}
      >
        <DialogTitle>Inviter un utilisateur</DialogTitle>
        <DialogContent>
          <Stack
            spacing={2}
            sx={{ marginTop: '10px' }}
          >
            <TextField
              fullWidth
              margin="dense"
              label="Nom de famille"
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
              {...register("lastName", { required: 'Ce champ est requis' })}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Prénom"
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
              {...register("firstName", { required: 'Ce champ est requis' })}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Adresse email"
              error={!!errors?.email}
              helperText={errors?.email?.message}
              {...register("email", {
                required: 'Ce champ est requis',
                pattern: {
                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: 'Ce champ doit être une adresse email'
                }
              })}
            />
            <TextField
              fullWidth
              margin="dense"
              type="password"
              label="Mot de passe temporaire"
              error={!!errors?.password}
              helperText={errors?.password ? errors?.password?.message :  "L'utilisateur rentrera son mot de passe définitif à sa première connexion"}
              {...register("password", {
                required: 'Ce champ est requis',
                pattern: {
                  value: /^(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~`-]).{8,}$/,
                  message: 'Le mot de passe doit contenir au moins 8 caractères, 1 chiffre et 1 caractère spécial'
                }
              })}
            />
            <TextField
              fullWidth
              margin="dense"
              type="password"
              label="Confirmer le mot de passe temporaire"
              error={!!errors?.passwordConfirm}
              helperText={errors?.passwordConfirm?.message}
              {...register("passwordConfirm", {
                required: 'Ce champ est requis',
                validate: (_, formValues) => formValues.password === formValues.passwordConfirm || 'Les mots de passe ne correspondent pas'
              })}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setModalOpen(false)}>
            Annuler
          </Button>
          <Button type='submit'>
            Valider
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { CreateUser };
