import axios from 'axios'
import { URL_API } from '../utils/utils'

const baseUrl = process.env.REACT_APP_MAIN_API_URL;

/**
 * Imports a graph into the system with the specified file content.
 *
 * @async
 * @function importGraph
 * @param {string} graph - The name of the graph.
 * @param {File} file - The file to be imported.
 * @param {string} fileContent - The content of the file.
 * @returns {Promise} - A Promise that resolves after the import request is completed.
 */
export async function importGraph(graph, file, fileContent) {
  const contentTypeMapping = {
    ttl: 'text/turtle',
    n3: 'text/turtle',
    jsonld: 'application/ld+json',
    json: 'application/json',
  }
  const re = /(?:\.([^.]+))?$/
  const extenstion = re.exec('file.name.with.dots.txt')[1]
  const formData = new FormData()
  const blob = new Blob([fileContent], {
    type: contentTypeMapping[extenstion],
  })
  formData.append('file_', blob, file.name)
  return axios
    .post(`${baseUrl}/metadata/dataset?graphname=${graph}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then()
    .catch((err) => {
      console.log(err)
      return err
    })
}

/**
 * Deletes a graph from the system.
 *
 * @async
 * @function delGraph
 * @param {string} graph - The name of the graph to be deleted.
 * @returns {Promise} - A Promise that resolves after the deletion request is completed.
 */
export async function delGraph(graph) {
  const url = `${baseUrl}/metadata/dataset?graphname=${graph}`
  return axios
    .delete(url)
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Update a graph on the system.
 *
 * @async
 * @function updateGraphs
 * @returns {Promise} - A Promise that resolves after the update is complete
 */
export async function updateGraph(graph, form) {
  const url = `${baseUrl}/metadata/dataset/update?graphname=${graph}`
  return axios
    .patch(url, form)
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Downloads the content of a graph in the specified file type.
 *
 * @async
 * @function downloadGraph
 * @param {string} graph - The name of the graph to download.
 * @param {string} filetype - The desired file type for the download.
 * @returns {Promise} - A Promise that resolves with the downloaded content.
 */
export async function downloadGraph(graph, filetype) {
  const url = `${baseUrl}/metadata/graph?graphname=${graph}&filetype=${filetype}`
  return axios
    .get(url)
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Retrieves card information for the specified graphs.
 *
 * @async
 * @function getCards
 * @param {Array} graphs - An array of graph names for which card information is requested.
 * @returns {Promise} - A Promise that resolves with the card information.
 */
export async function getCards(graphs) {
  const params = new URLSearchParams()
  if (graphs) {
    graphs.forEach((graph) => {
      params.append('graphs', graph)
    })
  } else if (graphs === false || graphs === null) {
    params.append('graphs', 'false')
  }
  const url = `${baseUrl}/cards?${params.toString()}`
  return axios
    .get(url)
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Performs a search using the specified term and selected filters.
 *
 * @async
 * @function search
 * @param {string} term - The search term.
 * @param {Object} selectedFilters - The selected filters for the search.
 * @returns {Promise} - A Promise that resolves with the search results.
 */
export async function search(term, selectedFilters) {
  let param = ''
  if (term) {
    param = `?term=${term}`
  }
  return axios
    .post(`${baseUrl}/search${param}`, selectedFilters)
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Asynchronously fetches data from the specified URL with optional parameters.
 *
 * @async
 * @function fetchData
 * @param {string} url - The URL from which to fetch the data.
 * @param {Object} params - Optional parameters to include in the request.
 * @returns {Promise} - A Promise that resolves with the fetched data.
 */
async function fetchData(url, params) {
  return axios
    .get(url, { params })
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Asynchronously retrieves metadata for a dataset with the specified graph name.
 *
 * @async
 * @function MetadataDataset
 * @param {string} graphname - The name of the graph for which metadata is requested.
 * @returns {Promise} - A Promise that resolves with the metadata for the dataset.
 */
export async function MetadataDataset(graphname) {
  const url = `${baseUrl}/metadata/dataset`
  const params = { graphname }
  return fetchData(url, params)
}

/**
 * Asynchronously retrieves metadata for a distribution within a specified graph.
 *
 * @async
 * @function MetadataDistribution
 * @param {string} graphname - The name of the graph containing the distribution.
 * @param {string} distribution - The name of the distribution for which metadata is requested.
 * @returns {Promise} - A Promise that resolves with the metadata for the distribution.
 */
export async function MetadataDistribution(graphname, distribution) {
  const url = `${baseUrl}/metadata/distribution`
  const params = { graphname, distribution }
  return fetchData(url, params)
}

/**
 * Asynchronously retrieves metadata for a CSV table within a specified distribution and graph.
 *
 * @async
 * @function MetadataCSVTable
 * @param {string} graphname - The name of the graph containing the distribution and table.
 * @param {string} distribution - The name of the distribution containing the table.
 * @param {string} table - The name of the CSV table for which metadata is requested.
 * @returns {Promise} - A Promise that resolves with the metadata for the CSV table.
 */
export async function MetadataCSVTable(graphname, distribution, table) {
  const url = `${baseUrl}/metadata/csv/table`
  const params = { graphname, distribution, table }
  return fetchData(url, params)
}

/**
 * Asynchronously retrieves metadata for a CSV variable within a specified distribution, table, and graph.
 *
 * @async
 * @function MetadataCSVVariable
 * @param {string} graphname - The name of the graph containing the distribution, table, and variable.
 * @param {string} distribution - The name of the distribution containing the table and variable.
 * @param {string} table - The name of the CSV table containing the variable.
 * @param {string} variable - The name of the CSV variable for which metadata is requested.
 * @returns {Promise} - A Promise that resolves with the metadata for the CSV variable.
 */
export async function MetadataCSVVariable(
  graphname,
  distribution,
  table,
  variable
) {
  const url = `${baseUrl}/metadata/csv/variable`
  const params = { graphname, distribution, table, variable }
  return fetchData(url, params)
}

/**
 * Asynchronously retrieves a list of CSV distributions within a specified graph.
 *
 * @async
 * @function MetadataCSVListDistributions
 * @param {string} graphname - The name of the graph for which distribution information is requested.
 * @returns {Promise} - A Promise that resolves with the list of CSV distributions.
 */
export async function MetadataCSVListDistributions(graphname) {
  return axios
    .get(`${baseUrl}/metadata/${graphname}/distributions`)
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Asynchronously retrieves a list of CSV tables within a specified distribution and graph.
 *
 * @async
 * @function MetadataCSVListTables
 * @param {string} graphname - The name of the graph containing the distribution and tables.
 * @param {string} distribution - The name of the distribution for which table information is requested.
 * @returns {Promise} - A Promise that resolves with the list of CSV tables.
 */
export async function MetadataCSVListTables(graphname, distribution) {
  return axios
    .get(
      `${baseUrl}/metadata/${graphname}/distributions/${distribution}/csv/tables`
    )
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Asynchronously retrieves a list of CSV variables within a specified distribution, table, and graph.
 *
 * @async
 * @function MetadataCSVListVariables
 * @param {string} graphname - The name of the graph containing the distribution, table, and variables.
 * @param {string} distribution - The name of the distribution containing the table and variables.
 * @param {string} table - The name of the CSV table containing the variables.
 * @returns {Promise} - A Promise that resolves with the list of CSV variables.
 */
export async function MetadataCSVListVariables(graphname, distribution, table) {
  return axios
    .get(
      `${baseUrl}/metadata/${graphname}/distributions/${distribution}/csv/tables/${table}/variables`
    )
    .then()
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Asynchronously sends a form for dataset metadata with the specified graph name.
 *
 * @async
 * @function SendForm
 * @param {string} graphname - The name of the graph for which the form is submitted.
 * @param {Object} form - The form data to be submitted for dataset metadata.
 * @returns {Promise} - A Promise that resolves after submitting the form.
 */
export async function SendForm(graphname, form) {
  return axios
    .post(
      `${baseUrl}/metadata/dataset/form?graphname=${graphname}`,
      JSON.stringify(form),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    )
    .then()
    .catch((err) => {
      console.log(err)
      return err
    })
}

/**
 * Asynchronously sends a form for updating dataset metadata with the specified graph name.
 *
 * @async
 * @function SendFormUpdate
 * @param {string} graphname - The name of the graph for which the form is submitted.
 * @param {Object} form - The form data to be submitted for updating dataset metadata.
 * @returns {Promise} - A Promise that resolves after submitting the update form.
 */
export async function SendFormUpdate(graphname, form) {
  return axios
    .post(
      `${baseUrl}/metadata/dataset/form/update?graphname=${graphname}`,
      JSON.stringify(form),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    )
    .then()
    .catch((err) => {
      console.log(err)
      return err
    })
}

export default async function ListInputs() {
  let result = []
  await axios
    .get(`${baseUrl}/structure`)
    .then((response) => {
      result = response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return result
}

/**
 * Asynchronously retrieves metadata for a dataset with the specified graph name.
 *
 * @async
 * @function ListMetadata
 * @param {string} document - The name of the graph for which metadata is requested.
 * @returns {Promise<Array>} - A Promise that resolves with an array containing the metadata for the dataset.
 */
export async function ListMetadata(document) {
  let metadata = []
  await axios
    .get(`${baseUrl}/metadata/dataset/form?graphname=${document}`)
    .then((response) => {
      metadata = response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return metadata
}

/**
 * Retrieves the list of available graphs from the system.
 *
 * @async
 * @function getGraphs
 * @returns {Promise} - A Promise that resolves with the list of graphs.
 */
export async function getMetadataFilters() {
  const url = `${baseUrl}/metadata/filters`
  return axios
    .get(url)
    .then()
    .catch((err) => {
      console.log(err)
    })
}
