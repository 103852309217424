import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box, Stack, ThemeProvider } from '@mui/material';
import AppBar from './components/AppBar';
import getTheme from './styles/Theme';
import { SnackContext, useSnack } from './utils/snack';
import Snack from './components/Snack';
import UsersList from './pages/users/List';
import DatasetsList from './pages/datasets/List';
import DatasetsView from './pages/datasets/View';
import Settings from './pages/settings';
import { SettingsProvider, useSettings } from './utils/settings';
import UserService from './utils/keycloak';

import '@fontsource/caveat/400.css';
import '@fontsource/comfortaa/400.css';
import '@fontsource/eb-garamond/400.css';
import '@fontsource/lexend/400.css';
import '@fontsource/lobster/400.css';
import '@fontsource/lora/400.css';
import '@fontsource/maven-pro/400.css';
import '@fontsource/merriweather/400.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/nunito/400.css';
import '@fontsource/oswald/400.css';
import '@fontsource/pacifico/400.css';
import '@fontsource/playfair-display/400.css';
import '@fontsource/roboto/400.css';
import '@fontsource/spectral/400.css';
import '@fontsource/syncopate/400.css';
import '@fontsource/ubuntu/400.css';

function App() {
  const { isSnackOpen, snackType, snackMessage, setSnackOpen, handleSnack } = useSnack();
  const snackContextValue = useMemo(() => ({ handleSnack }), [handleSnack]);
  const settings = useSettings();

  return (
    <ThemeProvider theme={getTheme(settings)}>
      <Stack>
        <BrowserRouter>
          <AppBar />
          <Box
            sx={{
              marginTop: '2%',
              padding: '0 24px'
            }}
          >
            <SnackContext.Provider value={snackContextValue}>
              <Routes>
                <Route path='/' element={<DatasetsList />} />
                <Route path='/users' element={<UsersList />} />
                <Route path='/datasets/:graphName' element={<DatasetsView />} />
                <Route path='/settings' element={<Settings />} />
              </Routes>
            </SnackContext.Provider>
            <Snack
              open={isSnackOpen}
              message={snackMessage}
              type={snackType}
              setOpen={setSnackOpen}
            />
          </Box>
        </BrowserRouter>
      </Stack>
    </ThemeProvider>
  );
}

function AppWrapper() {
  return (
    <SettingsProvider token={UserService.getToken()}>
      <App />
    </SettingsProvider>
  );
}

export default AppWrapper;
