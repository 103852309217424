import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'
import { AddSharp, EditSharp, DeleteSharp, CloudUpload } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {
  Paper,
  IconButton,
  Fab,
  CircularProgress,
  Stack,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  styled,
  DialogContentText,
  Typography,
} from '@mui/material';
import {
  getCards,
  delGraph,
  importGraph,
} from '../../lib/axios';
import { TextFieldSimple } from '../../styles/TextFields';
import SearchBar from '../../features/SearchBar';
import FilterBar from '../../features/Filters/FilterBar';
import SelectedFilters from '../../features/Filters/SelectedFilters';
import filters from '../../config/filters.json';
import { SnackContext } from '../../utils/snack';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function DatasetsList() {
  const [isCreationModalOpen, setCreationModalOpen] = useState(false);
  const [isDeletionValidationOpen, setDeletionValidationOpen] = useState(false);
  const [dbToDelete, setDbToDelete] = useState('');
  const [dbToCreate, setDbToCreate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState();
  const [, setInput] = useState('');
  const [checked, setChecked] = useState(() => {
    const listFilters = {}
    Object.keys(filters).forEach((element) => {
      listFilters[filters[element].SearchName] = []
    })
    return listFilters
  })

  const getCardsEffect = async () => {
    const response = await getCards()
    setCards(response)
  }

  const deleteDb = async(graph) => {
    try {
      const response = await delGraph(graph);
      if (response.status === 204) {
        handleSnack(`Base ${graph} supprimée`, 'success')
        setDeletionValidationOpen(false);
        getCardsEffect();
      }
    } catch {
      handleSnack('Un problème est apparu lors de la suppression', 'error')
    }
  }

  useEffect(() => {
    getCardsEffect().then(setLoading(false))
  }, [])

  return (
    <Grid spacing={2} container>
      <Dialog
        maxWidth='xs'
        open={isDeletionValidationOpen}
      >
        <DialogTitle>Valider la suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>Êtes-vous sûr de vouloir supprimer ce jeu de données ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDeletionValidationOpen(false)}>
            Annuler
          </Button>
          <Button onClick={() => deleteDb(dbToDelete)}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCreationModalOpen}
        onClose={() => setCreationModalOpen(false)}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            setIsLoading(true);
            const formData = new FormData(event.currentTarget);
            const { dbName, rdfFile }= Object.fromEntries((formData).entries());
            if (rdfFile === null) {
              handleSnack('Veuillez séléctionner un fichier', 'error')
            } else {
              const reader = new FileReader()
              reader.readAsText(rdfFile)
              reader.onload = async function () {
                const fileContent = reader.result
                const response = await importGraph(dbName, rdfFile, fileContent)
                if (response.status === 201) {
                  getCardsEffect();
                  setCreationModalOpen(false);
                  handleSnack('Base importé', 'success')
                  setDbToCreate(null);
                  setIsLoading(false);
                } else {
                  handleSnack(
                    response?.response?.data?.detail ?? `Une erreur s'est produite lors de l'import. Merci de vérifier la structure du fichier.`,
                    'error'
                  )
                  setDbToCreate(null);
                  setIsLoading(false);
                }
              }
            }
          },
        }}
      >
        <DialogTitle>Créer une base de données</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextFieldSimple
              autoFocus
              required
              margin="dense"
              name="dbName"
              label="Nom de la base"
              type="text"
              fullWidth
              variant="standard"
            />
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={isLoading ? <CircularProgress /> : <CloudUpload />}
              disabled={isLoading}
            >
              {dbToCreate || 'Télécharger fichier RDF (.ttl)'}
              <VisuallyHiddenInput
                type="file"
                name="rdfFile"
                onChange={(e) => setDbToCreate(e.target.files[0].name)}
              />
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreationModalOpen(false)}>
            Annuler
          </Button>
          <Button type="submit">Créer</Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Typography
          gutterBottom
          sx={{
            color: 'primary.main',
            fontSize: '1.5rem'
          }}
        >
          Gestion des bases
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SearchBar {...{ setCards, setInput, checked, setLoading }} />
      </Grid>
      <Grid item xs={12}>
        <SelectedFilters
          {...{
            checked,
            setChecked,
            setInput,
            setCards,
            setLoading,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FilterBar
          {...{
            checked,
            setChecked,
            setInput,
            setCards,
            setLoading,
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          {loading
            ? (
              <Grid sx={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress />
              </Grid>
            )
            : (
              <fragment>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Titre</TableCell>
                        <TableCell>Responsable du jeu de données</TableCell>
                        <TableCell align="center">Éditer</TableCell>
                        <TableCell align="center">Supprimer</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(cards?.data?.results?.bindings ?? []).map(({ graph_id: graphId, identifier, creator_name: creatorName }) => {
                        const url = new URL(graphId?.value);

                        return (
                          <TableRow key={identifier?.value}>
                            <TableCell>{identifier?.value}</TableCell>
                            <TableCell>{creatorName?.value}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                component={Link}
                                to={`/datasets/${url?.hostname}`}
                              >
                                <EditSharp />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                onClick={() => {
                                  setDeletionValidationOpen(true);
                                  setDbToDelete(url?.hostname);
                                }}
                              >
                                <DeleteSharp color="warning" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Fab
                  variant="extended"
                  color="primary"
                  type="submit"
                  onClick={() => setCreationModalOpen(true)}
                  sx={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                  }}
                >
                  <AddSharp sx={{ mr: 1 }} />
                  Créer un jeu de données
                </Fab>
              </fragment>
            )}
        </Stack>
      </Grid>
    </Grid>
  );
}

DatasetsList.propTypes = {
  handleSnack: PropTypes.func().isRequired
};

export default DatasetsList;
