import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Fab,
  Tabs,
  Tab,
} from '@mui/material';
import SaveSharpIcon from '@mui/icons-material/SaveSharp'
import { useForm, FormProvider } from 'react-hook-form';
import { Graphism } from './Graphism';
import { Links } from './Links';
import { LegalMentions } from './LegalMentions';
import { PrivacyPolicy } from './PrivacyPolicy';
import { postConfig, postLogo } from '../../lib/config';
import { useSettings } from '../../utils/settings';
import { SnackContext } from '../../utils/snack';
import UserService from '../../utils/keycloak'

function Settings() {
  const { handleSnack } = useContext(SnackContext);
  const [tabIndex, setTabIndex] = useState(0);
  const methods = useForm({
    defaultValues: {
      fontFamily: 'Arial'
    }
  });
  const settings = useSettings();

  useEffect(() => methods.reset({
    primaryColor: settings?.primaryColor,
    secondaryColor: settings?.secondaryColor,
    fontFamily: settings?.fontFamily,
    contactEmail: settings?.contactEmail,
    aboutUsUrl: settings?.aboutUsUrl,
    legalMentions: settings?.legalMentions,
    privacyPolicy: settings?.privacyPolicy,
  }), [settings, methods]);

  return (
    <fragment>
      <Typography
        gutterBottom
        sx={{
          color: 'primary.main',
          fontSize: '1.5rem'
        }}
      >
        Paramètres
      </Typography>
      <Box sx={{ paddingBottom: '20px' }}>
        <Tabs value={tabIndex} onChange={(_, value) => setTabIndex(value)}>
          <Tab label="Graphismes" />
          <Tab label="Liens" />
          <Tab label="Mention légales" />
          <Tab label="Politique de confidentialité" />
        </Tabs>
      </Box>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async(values) => {
            try {
              await postConfig(UserService.getToken(), {
                primaryColor: values.primaryColor,
                secondaryColor: values.secondaryColor,
                fontFamily: values.fontFamily,
                contactEmail: values.contactEmail,
                aboutUsUrl: values.aboutUsUrl,
                legalMentions: values?.legalMentions,
                privacyPolicy: values?.privacyPolicy,
              });

              if (values.logo) {
                await postLogo(UserService.getToken(), values.logo);
              }

              await settings.fetch();
              handleSnack('Paramètres mis à jour avec succès', 'success');
            } catch (error) {
              console.error(error);
              handleSnack('Une erreur est survenue');
            }
          })}
        >
          <Box
            role='tabpanel'
            hidden={tabIndex !== 0}
          >
            <Graphism />
          </Box>
          <Box
            role='tabpanel'
            hidden={tabIndex !== 1}
          >
            <Links />
          </Box>
          <Box
            role='tabpanel'
            hidden={tabIndex !== 2}
          >
            <LegalMentions />
          </Box>
          <Box
            role='tabpanel'
            hidden={tabIndex !== 3}
          >
            <PrivacyPolicy />
          </Box>
          <Fab
            variant='extended'
            color='primary'
            type='submit'
            sx={{
              margin: 0,
              top: 'auto',
              right: 20,
              bottom: 20,
              left: 'auto',
              position: 'fixed',
            }}
          >
            <SaveSharpIcon sx={{ mr: 1 }} />
            Enregistrer
          </Fab>
        </form>
      </FormProvider>
    </fragment>
  );
}

export default Settings;
