import { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Box,
  Fab,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material'
import SaveSharpIcon from '@mui/icons-material/SaveSharp'
import { MetadataDataset, updateGraph } from '../../lib/axios'
import GenericCard from '../../features/GenericCard'
import { SnackContext } from '../../utils/snack';

function DatasetsView() {
  const { graphName } = useParams();
  const navigate = useNavigate();
  const [metadata, setMetadata] = useState()
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const { handleSnack } = useContext(SnackContext);

  const { getFieldState, ...methods } = useForm();

  useEffect(() => {
    const getMetadata = async () => {
      try {
        setMetadata(await MetadataDataset(graphName))
        setLoading(false)
      } catch (error) {
        console.error(error);
        handleSnack('Un problème est apparu lors de la suppression', 'error')
      }
    }
    getMetadata()
  }, [graphName, handleSnack])

  return (
    loading
      ? <CircularProgress />
      : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(async(values) => {
              const newValues = Object.keys(values).reduce((acc, key) => {
                const { isDirty } = getFieldState(key);
                if (isDirty) {
                  return { ...acc, [key]: values[key] };
                }
                return acc;
              }, {});
              try {
                await updateGraph(graphName, newValues);
                handleSnack('Mise à jour des métadonnées effectuée', 'success');
                navigate('/');
              } catch (error) {
                console.error(error);
                handleSnack('Un problème est apparu lors de la mise à jour des données', 'error');
              }
            })}
          >
            <Box sx={{ width: '100%' }}>
              <Box sx={{ paddingBottom: '20px' }}>
                <Tabs value={tabIndex} onChange={(_, value) => setTabIndex(value)}>
                  <Tab label="Métadonnées" />
                </Tabs>
              </Box>
              <Box
                role="tabpanel"
                hidden={tabIndex !== 0}
              >
                {metadata
                  ? Object.entries(metadata?.data).map(([category, values]) => (
                      <GenericCard
                        key={category}
                        category={category}
                        metadata={values}
                        showEmpty
                      />
                    ))
                  : null}
              </Box>
              <div
                role="tabpanel"
                hidden={tabIndex !== 1}
              >
                Collections edit tab
              </div>
            </Box>
            <Fab
              variant='extended'
              color="primary"
              type="submit"
              sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 20,
                left: 'auto',
                position: 'fixed',
              }}
            >
              <SaveSharpIcon sx={{ mr: 1 }} />
              Enregistrer
            </Fab>
          </form>
        </FormProvider>
      )
  );
}

DatasetsView.propTypes = {
  handleSnack: PropTypes.func().isRequired
};

export default DatasetsView;
