import { useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@mui/material'
import { SnackContext } from '../../utils/snack';
import { deleteUser } from '../../lib/keycloak';
import UserService from '../../utils/keycloak';

const DeleteUser = ({ isOpen, setModalOpen, refresh, user }) => {
  const { handleSnack } = useContext(SnackContext);

  return (
    <Dialog
      maxWidth='xs'
      open={isOpen}
    >
      <DialogTitle>Valider la suppression de {user?.firstName} {user?.lastName}</DialogTitle>
      <DialogContent>
        <DialogContentText>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => setModalOpen(false)}
        >
          Annuler
        </Button>
        <Button
          onClick={async() => {
            try {
              await deleteUser(UserService.getToken(), user?.id);
              setModalOpen(false);
              handleSnack('Utilisateur supprimé', 'success');
              refresh();
            } catch (error) {
              console.error(error);
              handleSnack('Erreur lors de la suppression de l\'utilisateur', 'error');
            }
          }}
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteUser };
