import { Controller, useFormContext } from "react-hook-form";
import MDEditor from '@uiw/react-md-editor';

const PrivacyPolicy = () => {
  const { control } = useFormContext();

  return (
    <div data-color-mode="light">
      <Controller
        control={control}
        name='privacyPolicy'
        render={({ field }) => (
          <MDEditor {...field} />
        )}
      />
    </div>
  );
}

export { PrivacyPolicy };
