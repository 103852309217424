import axios from 'axios'

const BASE_URL = process.env.REACT_APP_ADMIN_API_URL;

const postConfig = async(token, data) => {
  try {
    await axios.post(`${BASE_URL}/config/settings`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error(error);
  }
};

const postLogo = async(token, logo) => {
  try {
    await axios.post(`${BASE_URL}/config/logo`, {
      file: logo
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error(error);
  }
}

export { postConfig, postLogo };
