import { useFormContext, Controller } from 'react-hook-form';
import {
  styled,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSettings } from '../../utils/settings';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Graphism = () => {
  const { control, watch } = useFormContext();
  const settings = useSettings();
  const logoData = watch('logo');

  return (
    <Box
      sx={{
        display: 'flex',
        width: '50%',
        flexDirection: 'column',
        gap: '1rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flex: {
            justifyContent: 'space-between',
            gap: '1rem'
          }
        }}
      >
        <Controller
          control={control}
          name='primaryColor'
          render={({ field: { onChange, ...field } }) => (
            <MuiColorInput
              label='Couleur primaire'
              format='hex'
              sx={{ width: '100%' }}
              onChange={(color) => onChange(color)}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='secondaryColor'
          render={({ field: { onChange, ...field } }) => (
            <MuiColorInput
              label='Couleur secondaire'
              format='hex'
              sx={{ width: '100%' }}
              onChange={(color) => onChange(color)}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <FormControl fullWidth>
          <InputLabel>Police d&apos;écriture</InputLabel>
          <Controller
            control={control}
            name='fontFamily'
            render={({ field }) => (
              <Select
                label="Police d'écriture"
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                {...field}
              >
                <MenuItem
                  value='Arial'
                  sx={{ fontFamily: 'Arial' }}
                >
                  Arial
                </MenuItem>
                <MenuItem
                  value='Caveat'
                  sx={{ fontFamily: 'Caveat' }}
                >
                  Caveat
                </MenuItem>
                <MenuItem
                  value='Comfortaa'
                  sx={{ fontFamily: 'Comfortaa' }}
                >
                  Comfortaa
                </MenuItem>
                <MenuItem
                  value='Comic Sans MS'
                  sx={{ fontFamily: 'Comic Sans MS' }}
                >
                  Comic Sans MS
                </MenuItem>
                <MenuItem
                  value='Courier New'
                  sx={{ fontFamily: 'Courier New' }}
                >
                  Courier New
                </MenuItem>
                <MenuItem
                  value='EB Garamond'
                  sx={{ fontFamily: 'EB Garamond' }}
                >
                  EB Garamond
                </MenuItem>
                <MenuItem
                  value='Lexend'
                  sx={{ fontFamily: 'Lexend' }}
                >
                  Lexend
                </MenuItem>
                <MenuItem
                  value='Lobster'
                  sx={{ fontFamily: 'Lobster' }}
                >
                  Lobster
                </MenuItem>
                <MenuItem
                  value='Lora'
                  sx={{ fontFamily: 'Lora' }}
                >
                  Lora
                </MenuItem>
                <MenuItem
                  value='Maven Pro'
                  sx={{ fontFamily: 'Maven Pro' }}
                >
                  Maven Pro
                </MenuItem>
                <MenuItem
                  value='Merriweather'
                  sx={{ fontFamily: 'Merriweather' }}
                >
                  Merriweather
                </MenuItem>
                <MenuItem
                  value='Montserrat'
                  sx={{ fontFamily: 'Montserrat' }}
                >
                  Montserrat
                </MenuItem>
                <MenuItem
                  value='Nunito'
                  sx={{ fontFamily: 'Nunito' }}
                >
                  Nunito
                </MenuItem>
                <MenuItem 
                  value='Oswald'
                  sx={{ fontFamily: 'Oswald' }}
                >
                  Oswald
                </MenuItem>
                <MenuItem
                  value='Pacifico'
                  sx={{ fontFamily: 'Pacifico' }}
                >
                  Pacifico
                </MenuItem>
                <MenuItem
                  value='Playfair Display'
                  sx={{ fontFamily: 'Playfair Display' }}
                >
                  Playfair Display
                </MenuItem>
                <MenuItem
                  value='Roboto'
                  sx={{ fontFamily: 'Roboto' }}
                >
                  Roboto
                </MenuItem>
                <MenuItem
                  value='Spectral'
                  sx={{ fontFamily: 'Spectral' }}
                >
                  Spectral
                </MenuItem>
                <MenuItem
                  value='Syncopate'
                  sx={{ fontFamily: 'Syncopate' }}
                >
                  Syncopate
                </MenuItem>
                <MenuItem
                  value='Ubuntu'
                  sx={{ fontFamily: 'Ubuntu' }}
                >
                  Ubuntu
                </MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center'
        }}
      >
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Télécharger un logo
          <Controller
            control={control}
            name='logo'
            render={({ field: { onChange, value, ...field } }) => (
              <VisuallyHiddenInput
                type='file'
                accept='image/*'
                value=''
                onChange={(e) => onChange(e.target.files[0])}
                {...field}
              />
            )}
          />
        </Button>
        {logoData
          ? (
            <Box
              component='img'
              alt='logo-preview'
              src={URL.createObjectURL(logoData)}
              sx={{
                maxWidth: '85.55px',
                maxHeight: '58.61px'
              }}
            />
          )
          : (
            <Box
              component='img'
              alt='logo-preview'
              src={settings?.logo}
              sx={{
                maxWidth: '85.55px',
                maxHeight: '58.61px'
              }}
            />
          )
        }
      </Box>
    </Box>
  );
};

export { Graphism };
