import { useEffect, useState } from 'react'
import { AddSharp, DeleteSharp, StorageSharp } from '@mui/icons-material';
import {
  Paper,
  IconButton,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { getUsers } from '../../lib/keycloak'
import UserService from '../../utils/keycloak'

import { CreateUser } from './CreateUser'
import { AssignDb } from './AssignDb'
import { DeleteUser } from './DeleteUser'

function Users() {
  const [users, setUsers] = useState();
  const [userAction, setUserAction] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [isAssignDbModalOpen, setAssignDbModalOpen] = useState(false);

  const fetchUsers = async(token) => {
    const usersData = await getUsers(token);
    setUsers(usersData);
    setIsLoading(false);
  }

  const deleteUser = (user) => {
    setUserAction(user);
    setDeleteUserModalOpen(true);
  }

  useEffect(() => {
    if (UserService.getToken()) {
      fetchUsers(UserService.getToken());
    }
  }, []);

  return (
    <fragment>
      <CreateUser
        isOpen={isCreateUserModalOpen}
        refresh={() => fetchUsers(UserService.getToken())}
        setModalOpen={setCreateUserModalOpen}
      />
      <DeleteUser
        user={userAction}
        refresh={() => fetchUsers(UserService.getToken())}
        isOpen={isDeleteUserModalOpen}
        setModalOpen={setDeleteUserModalOpen}
      />
      <AssignDb
        isOpen={isAssignDbModalOpen}
        setModalOpen={setAssignDbModalOpen}
      />
      <Typography
        gutterBottom
        sx={{
          color: 'primary.main',
          fontSize: '1.5rem'
        }}
      >
        Gestion des utilisateurs
      </Typography>
      {isLoading
        ? (
          <Box sx={{ width: '100%', textAlign: 'center', my: '4rem' }}>
            <CircularProgress />
          </Box>
        )
        : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Prénom</TableCell>
                  <TableCell>Nom de famille</TableCell>
                  <TableCell>Adresse e-mail</TableCell>
                  {/* <TableCell align="center">Assigner un jeu de données</TableCell> */}
                  <TableCell align="center">Supprimer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(users ?? []).map((user) => (
                  <TableRow key={user?.email}>
                    <TableCell>{user?.firstName}</TableCell>
                    <TableCell>{user?.lastName}</TableCell>
                    <TableCell>{user?.email}</TableCell>
                    {/* <TableCell align="center">
                      <IconButton onClick={() => setAssignDbModalOpen(true)}>
                        <StorageSharp />
                      </IconButton>
                    </TableCell> */}
                    <TableCell align="center">
                      <IconButton onClick={() => deleteUser(user)}>
                        <DeleteSharp color="warning" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
      <Fab
        variant="extended"
        color="primary"
        type="submit"
        onClick={() => setCreateUserModalOpen(true)}
        sx={{
          margin: 0,
          top: 'auto',
          right: 20,
          bottom: 20,
          left: 'auto',
          position: 'fixed',
        }}
      >
        <AddSharp sx={{ mr: 1 }} />
        Inviter un utilisateur
      </Fab>
    </fragment>
  );
}

export default Users;
