import { useState, useCallback, createContext } from 'react';

const useSnack = () => {
  const [isSnackOpen, setSnackOpen] = useState(false);
  const [snackType, setSnackType] = useState();
  const [snackMessage, setSnackMessage] = useState();

  const handleSnack = useCallback((msg, errortype) => {
    setSnackMessage(msg)
    setSnackType(errortype)
    setSnackOpen(true)
  }, []);

  return { isSnackOpen, snackType, snackMessage, setSnackOpen, handleSnack };
};

const SnackContext = createContext();

export { useSnack, SnackContext };
