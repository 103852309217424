import { Controller, useFormContext } from 'react-hook-form';
import MDEditor from '@uiw/react-md-editor';

const LegalMentions = () => {
  const { control } = useFormContext();

  return (
    <div data-color-mode="light">
      <Controller
        control={control}
        name='legalMentions'
        render={({ field }) => (
          <MDEditor {...field} />
        )}
      />
    </div>
  );
}

export { LegalMentions };
