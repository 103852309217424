import axios from 'axios'

const BASE_URL = process.env.REACT_APP_KEYCLOAK_URL;
const REALM_NAME = process.env.REACT_APP_KEYCLOAK_REALM;

const getUsers = async(token) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/realms/${REALM_NAME}/users`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }

  return false;
};

const deleteUser = async(token, userId) => {
  try {
    await axios.delete(`${BASE_URL}/admin/realms/${REALM_NAME}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error(error);
  }
};

const createUser = async(token, user) => {
  try {
    // Create user
    await axios.post(`${BASE_URL}/admin/realms/${REALM_NAME}/users`, {
      username: `${user?.firstName}-${user?.lastName}`,
      enabled: true,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    // Get user ID
    const { data: [newUser] } = await axios.get(`${BASE_URL}/admin/realms/${REALM_NAME}/users?username=${user?.firstName}-${user.lastName}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    // Create temp password
    await axios.put(`${BASE_URL}/admin/realms/${REALM_NAME}/users/${newUser.id}/reset-password`, {
      type: 'password',
      value: user?.password,
      temporary: true
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export {
  getUsers,
  deleteUser,
  createUser
};
